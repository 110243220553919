export default function IconLocalisation() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="20"
        fill="none"
        viewBox="0 0 15 20"
        >
        <path
            fill="#6F58C9"
            d="M7.433 4.702a2.736 2.736 0 100 5.471 2.736 2.736 0 000-5.471zm0 4.103a1.368 1.368 0 110-2.735 1.368 1.368 0 010 2.735z"
        ></path>
        <path
            fill="#6F58C9"
            d="M12.69 2.177A7.434 7.434 0 001.205 11.49l5.168 7.934a1.265 1.265 0 002.121 0l5.168-7.934a7.434 7.434 0 00-.972-9.314zm-.173 8.568l-5.083 7.803-5.083-7.803a6.094 6.094 0 01.793-7.601 6.066 6.066 0 018.58 0 6.094 6.094 0 01.793 7.6z"
        ></path>
        </svg>
    )
}