import { useLocation } from "react-router-dom";
import Map from "../components/map";


export default function Wheel() {
    const location = useLocation();
    if(!location.state) {
        window.location.assign("/");
    }
    window.scrollTo(0,0);
    document.querySelector('.logo').classList.add('logo-loading');

    const request = {
        placeId: location.state.place_id,
    };

    let map: google.maps.Map;
    let service: google.maps.places.PlacesService;
    let city = new google.maps.LatLng(location.state.map.lat, location.state.map.lng);
    map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: city,
        zoom: 10,
    });
    service = new google.maps.places.PlacesService(map);
    service.getDetails(request, callback);

    function confettis() {
        for(var i=0;  i<500;  i++) {
            // Random rotation
            var randomRotation = Math.floor(Math.random() * 360);
              // Random Scale
            var randomScale = Math.random() * 1;
            // Random width & height between 0 and viewport
            var randomWidth = Math.floor(Math.random() * Math.max(document.documentElement.clientWidth, window.innerWidth || 0));
            var randomHeight =  Math.floor(Math.random() * Math.max(document.documentElement.clientHeight, window.innerHeight || 500));
            
            // Random animation-delay
            var randomAnimationDelay = Math.floor(Math.random() * 15);
          
            // Random colors
            var colors = ['#0CD977', '#FF1C1C', '#FF93DE', '#5767ED', '#FFC61C', '#8497B0']
            var randomColor = colors[Math.floor(Math.random() * colors.length)];
          
            // Create confetti piece
            var confetti = document.createElement('div');
            confetti.className = 'confetti';
            confetti.style.top=randomHeight + 'px';
            confetti.style.right=randomWidth + 'px';
            confetti.style.backgroundColor=randomColor;
            confetti.style.transform='skew(15deg) rotate(' + randomRotation + 'deg)';
            confetti.style.animationDelay=randomAnimationDelay + 's';
            document.getElementById("confetti-wrapper").appendChild(confetti);
          }
    }

    function callback(place, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            console.log(place);
            document.getElementById("restaurant-name").textContent = place.name;
            (document.getElementById("restaurant-img") as HTMLImageElement).src  = place.photos[0].getUrl({maxWidth: 500, maxHeight: 500});
            (document.getElementById("restaurant-tel") as HTMLLinkElement).href  = "tel:"+place.international_phone_number;
            if(place.opening_hours.weekday_text.length) {
                const current = (document.getElementById("restaurant-horraires") as HTMLElement);
                current.replaceChildren();
                place.opening_hours.weekday_text.forEach(e => {
                    const p = document.createElement("p");
                    const content = document.createTextNode(e);
                    p.appendChild(content);
                    current.append(p);
                })
            }
            if(place.website) {
                (document.getElementById("restaurant-web") as HTMLLinkElement).href  = place.website;
            } else {
                document.getElementById("restaurant-web").remove();
            }
            (document.getElementById("restaurant-adress") as HTMLLinkElement).href  = place.url;
            (document.getElementById("restaurant-vincity") as HTMLElement).textContent  = place.vicinity;
            setTimeout(function(){
                confettis();
                document.querySelector('.logo').classList.remove('logo-loading');
                (document.querySelector('.wheel') as HTMLElement).style.opacity = '0';
                setTimeout(function(){
                    if (place.geometry.viewport) {
                        map.fitBounds(place.geometry.viewport);
                    } else {
                        map.setCenter(place.geometry.location);
                    }
                    map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
                        center: place.geometry.location,
                        zoom: 18,
                    });
                    new google.maps.Marker({
                        map,
                        position: place.geometry.location,
                    });
                    (document.querySelector('.wheel') as HTMLElement).style.display = 'none';
                    (document.querySelector('.result') as HTMLElement).style.display = 'flex';
                    (document.querySelector('.result') as HTMLElement).style.opacity = '1';
                }, 300)
            }, 1000)
        }
    }
    
    return (
        <div className="results">
            <div className="wheel">
                <h2 className="call-purple">Nous cherchons le restaurant correspondant à vos critères 😋</h2>
                <div className="wheel-loader">
                    <div className="res">Le restaurant machin</div>
                    <div className="res">Chez bidule</div>
                    <div className="res">Mangez moi !</div>
                    <div className="res">Couscous ça va ?</div>
                    <div className="res">Restau Universitaire</div>
                </div>
            </div>
            <div className="result">
                <header className="result-header">
                    <h2 id="restaurant-name" className="call-purple"></h2>
                    <img id="restaurant-img" src=""/>
                    <div id="confetti-wrapper"></div>
                </header>
                <p id="restaurant-vincity"></p>
                <Map/>
                <a target="_blank" className="button button-text  button-cta  " id="restaurant-web" href="">Voir le site du Restaurant</a>
                <a id="restaurant-tel" className="button button-text  button-cta  " href="">Appeler le Restaurant</a>
                <a target="_blank" className="button button-text  button-cta  " id="restaurant-adress" href="">Comment m'y rendre ?</a>
                <p><b>Horraires :</b></p>
                <div id="restaurant-horraires"></div>
            </div>
        </div>
        )
    }