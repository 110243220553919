export default function IconInfo() {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <g fill="#F6E8EA" clipPath="url(#clip0_7_94)">
            <path d="M17.5 1.25a1.25 1.25 0 011.25 1.25v15a1.25 1.25 0 01-1.25 1.25h-15a1.25 1.25 0 01-1.25-1.25v-15A1.25 1.25 0 012.5 1.25h15zM2.5 0A2.5 2.5 0 000 2.5v15A2.5 2.5 0 002.5 20h15a2.5 2.5 0 002.5-2.5v-15A2.5 2.5 0 0017.5 0h-15z"></path>
            <path d="M11.162 8.235L8.3 8.594l-.103.475.563.103c.367.088.44.22.36.587l-.922 4.335c-.243 1.121.13 1.649 1.01 1.649.68 0 1.472-.316 1.83-.748l.11-.52c-.25.22-.614.308-.857.308-.344 0-.469-.242-.38-.667l1.252-5.881zm.088-2.61a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z"></path>
          </g>
          <defs>
            <clipPath id="clip0_7_94">
              <path fill="#fff" d="M0 0H20V20H0z"></path>
            </clipPath>
          </defs>
        </svg>
      )
}