import IconInfo from "./components/icons/icon-info";

export default function Header() {
    return (
        <header className="header">
            <h2 className="logo"><a href="/">
                <span className="header-char1">R</span>
                <span className="header-char2">e</span>
                <span className="header-char3">s</span>
                <span className="header-char4">t</span>
                <span className="header-char5">o</span>
                <span className="header-char5">&nbsp;</span>
                <span className="header-char6">R</span>
                <span className="header-char7">o</span>
                <span className="header-char8">u</span>
                <span className="header-char9">l</span>
                <span className="header-char10">e</span>
                <span className="header-char11">t</span>
                <span className="header-char12">t</span>
                <span className="header-char13">e</span>
                </a>
            </h2>
            <a href="/wheel"><IconInfo/></a>
        </header>
    )
}

function loader() {
    document.querySelector('header > h2')?.classList.add('logo-loading');
    setTimeout(function(){
        document.querySelector('header > h2')?.classList.remove('logo-loading');
    }, 2000);
}