import {useState} from 'react';
import { forEachLeadingCommentRange } from 'typescript';
import "../assets/scss/components/buttons.scss";

export default function Button(props) {
    const [isActive, setIsActive] = useState(false);
    function handleClick() {
        setIsActive(current => !current);
        if(props.only) {
            const parent = document.activeElement.parentElement.children;
            const children = Array.from(parent);
            const current = children.indexOf(document.activeElement);
            for (let index = 0; index < current; index++) {
                const element = parent[index];
                if(isActive) {
                    element.classList.remove("clicked");
                } else {
                    element.classList.add("clicked");
                }
            }
        }
    };
    return (
        <button 
        data-content={props.datacontent}
        onClick={() => {
            props.func ? props.func() : handleClick();
        }} 
        className={
            "button "+
            (props.icon ? "button-icon " : "button-text ")+
            (props.addClass ? props.addClass+" " : " ")+
            (props.cta ? "button-cta " : " ")+
            (props.click ? "button-click "+(isActive ? "clicked " : " ") : " ")
        }>
            {props.content}
        </button>
    )
}