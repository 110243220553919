export default function Footer() {
    return (
        <footer>
            <h2 className="logo"><a href="/">Resto Roulette</a></h2>
            <h2><a href="/">Accueil</a></h2>
            <h2><a href="/account">Mon compte</a></h2>
            <h2><a href="/results">Mes résultats</a></h2>
            <h2><a href="/reductions">Mes réductions</a></h2>
            <h2><a href="/contact">Contact</a></h2>
            <h2><a href="/about">À-propos</a></h2>
            <h2><a href="/legal">Mentions légales</a></h2>
            <h2><a href="/rgpd">RGPD</a></h2>
            <div className="separator"></div>
            <h2><a href="/partners">Devenir partenaire</a></h2>
            <h2><a href="/partners-space">Espace partenaire</a></h2>
            <p>Fait par <a href="https://antoine.world/" target="_blank">Antoine Demacon</a> avec ❤️</p>
        </footer>
        )
    }