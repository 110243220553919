import Button from "../components/Button";
import Map from "../components/map";
import IconLocalisation from "../components/icons/icon-position";
import {useNavigate} from 'react-router-dom';

export default function Home() {
    let map: google.maps.Map;
    let service: google.maps.places.PlacesService;
    let center = { lat: 44.857300666917986, lng:  -0.5736702727727853 };
    
    let res = [];
    const navigate = useNavigate();
    const Mark = (e, res) => {
        res = res[Math.floor(Math.random()*res.length)];
        navigate("/wheel", { 
            state : { 
                place_id : res.place_id,
                map : center,
            } 
        })
    }
    
    function getGeo() {
        document.querySelector('.logo').classList.add('logo-loading');
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };
        navigator.geolocation.getCurrentPosition(success, error, options);
        
        function success(pos) {
            const crd = pos.coords;
            center.lat = crd.latitude;
            center.lng = crd.longitude;
            initMap();
            document.getElementById("map").classList.add("opened");
        }
        function error(err) {
            console.warn(`ERROR(${err.code}): ${err.message}`);
        }
    }
    
    function initMap() {
        document.querySelector('.logo').classList.add('logo-loading');
        let city = new google.maps.LatLng(center.lat, center.lng);
        map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
            center: city,
            zoom: 16,
        });
        SearchNearby(city);

        const input = document.getElementById("searchBox")  as HTMLInputElement;
        var searchBox = new google.maps.places.SearchBox(input);
        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();
            if (places.length === 1) {
                var place = places[0];
                if (place.geometry.viewport) {
                    map.fitBounds(place.geometry.viewport);
                } else {
                    map.setCenter(place.geometry.location);
                }
                document.getElementById("map").classList.add("opened");
                SearchNearby(place.geometry.location);
            }
        });
        
        function SearchNearby(city){
            new google.maps.Marker({
                map,
                position: city,
            });
            const maxPrice = document.querySelectorAll('#budget > .clicked').length;
            const distance = document.querySelectorAll('#meters > .clicked');
            const radius = parseInt(distance[(distance.length - 1)].getAttribute('data-content'));
            const regime = document.querySelectorAll('#regime > .clicked');
            let keywords = [];
            regime.forEach((element) => {
                keywords.push(element.getAttribute('data-content'));
            });
            keywords.push((document.querySelector('#other-keywords') as HTMLInputElement).value);
            console.log("restaurant " + (keywords.length ? keywords.join(" ") : ''));
            
            const request = {
                location : city,
                radius : radius,
                query : "restaurant " + (keywords.length ? keywords.join(" ") : ''),
                maxPriceLevel : maxPrice
            };

            console.log(request);

            service = new google.maps.places.PlacesService(map);
            service.textSearch(request, callback);
            function callback(results, status) {
                console.log(status);
                if(status === "ZERO_RESULTS") {

                }
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    res = results.filter(result => result.business_status !== "CLOSED_TEMPORARILY");
                    document.querySelector('.logo').classList.remove('logo-loading');
                }
            }
        }
    }
    
    return (
        <div className="home">
        <h2 className="call-purple">Sélectionnez vos critères et laissez vous guider par la roue 🥰</h2>
        <div className="card">
        <header><Button icon content="🛹"/><p className="card-title">Distance</p></header>
        <div className="card-content">
        <div className="card-icons" id="meters">
        <Button addClass="clicked" icon click datacontent="250" content="🚶‍♀️"/>
        <Button icon click datacontent="1000" content="🚲"/>
        <Button icon click datacontent="5000" content="🚗"/>
        </div>
        </div>
        </div>
        <div className="card">
        <header><Button icon content="💸"/><p className="card-title">Budget</p></header>
        <div className="card-content">
        <div className="card-icons" id="budget">
        <Button addClass="clicked" only icon click datacontent="1" content="🤑"/>
        <Button only icon click datacontent="2" content="🤑"/>
        <Button only icon click datacontent="3" content="🤑"/>
        <Button only icon click datacontent="4" content="🤑"/>
        </div>
        </div>
        </div>
        <div className="card">
        <header><Button icon content="🍰"/><p className="card-title">Régime alimentaire</p></header>
        <div className="card-content">
        <div className="card-selectors" id="regime">
        <Button click datacontent="végétarien" content="végétarien"/>
        <Button click datacontent="hallal" content="hallal"/>
        <Button click datacontent="sans gluten" content="sans gluten"/>
        <input id="other-keywords" type="text" placeholder="Autre" />
        </div>
        </div>
        </div>
        <div className="card">
        <header><Button icon content="📍"/><p className="card-title">Localisation</p></header>
        <div className="card-content">
        <input onClick={initMap} id="searchBox" type="text" placeholder="Mon adresse" />
        <div id="localisation-button" onClick={getGeo}><IconLocalisation/></div>
        </div>
        <Map/>
        </div>
        <Button cta func={e => Mark(e, res)} content="FAIRE TOURNER LA ROUE !"/>
        </div>
        );
    }
    